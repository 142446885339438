import { CommonModule } from '@angular/common';
import { Component, effect, input, Input, OnInit, untracked } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Loader } from '@googlemaps/js-api-loader';
import { AppService } from '@shared/services/app.service';
import { AutoCompleteCompleteEvent, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-google-address',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, AutoCompleteModule],
  templateUrl: './google-address.component.html',
  styleUrls: ['./google-address.component.scss']
})
export class GoogleAddressComponent implements OnInit {
  @Input() public parent: FormGroup;
  @Input() public control: string;
  @Input() public street: string = 'streetName';
  @Input() public city: string = 'cityName';
  @Input() public state: string = 'stateName';
  @Input() public zipcode: string = 'zipcode';
  @Input() public appendTo: string | undefined = undefined;
  public initValue = input<string | undefined>(undefined);

  public selectedAddress: string = '';
  public autocompleteService: google.maps.places.AutocompleteService;
  public options: any[] = [];

  public form: FormGroup;
  public autoCompletePos: 'auto' | 'above' | 'below' = 'auto';

  private loader: Loader;

  public constructor(
    private fb: FormBuilder,
    private appService: AppService
  ) {
    this.loader = new Loader({
      apiKey: environment.firebase.apiKey,
      version: 'weekly',
      libraries: ['places']
    });

    this.form = this.fb.group({
      address: [this.initValue() || '']
    });

    effect(() => {
      if (this.initValue() && this.form) {
        untracked(() => {
          this.form.patchValue({
            address: this.initValue()
          });

          setTimeout(() => {
            if (this.autocompleteService) {
              this.getAddressPredictions(this.initValue()!, this.handleAutoSearch.bind(this));
            }
          }, 1000);
        });
      }
    });
  }

  public get isIOS(): boolean {
    return this.appService.getOS() === 'iOS';
  }

  public get hasError(): boolean {
    return !!(this.parent.get(this.control)?.invalid && this.parent.get(this.control)?.touched);
  }

  public ngOnInit(): void {
    this.loader.load().then(() => {
      this.autocompleteService = new google.maps.places.AutocompleteService();
    });

    if (this.isIOS) {
      this.autoCompletePos = 'below';
    }
  }

  public onFocus(event: any) {
    if (this.isIOS) {
      setTimeout(() => {
        event.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 300);
    }
  }

  public onAddressInputChange(event: AutoCompleteCompleteEvent) {
    this.selectedAddress = event.query;

    this.parent.get(this.control)?.reset();
    this.parent.get(this.city)?.reset();
    this.parent.get(this.state)?.reset();
    this.parent.get(this.zipcode)?.reset('');

    this.getAddressPredictions(this.selectedAddress);
  }

  public onSelected(event: AutoCompleteSelectEvent): void {
    const addressValue =
      event.value.description.indexOf(event.value.zipcode) > -1 ? event.value.description : `${event.value.description} ${event.value.zipcode}`;
    this.parent.get(this.control)?.setValue(`${addressValue}`);
    this.parent.get(this.street)?.setValue(event.value.structured_formatting.main_text);
    this.parent.get(this.city)?.setValue(event.value.city);
    this.parent.get(this.state)?.setValue(event.value.state);
    this.parent.get(this.zipcode)?.setValue(event.value.zipcode);
  }

  public displayFn(address: any): string {
    return address && address.description ? address.description : this.initValue() || '';
  }

  private getAddressPredictions(address: string, cb?: (predictions: any[]) => void) {
    // Call the Google Maps Autocomplete API
    this.autocompleteService.getPlacePredictions(
      { input: address, componentRestrictions: { country: 'us' }, types: ['address'] },
      (predictions: any, status: any) => {
        if (status === 'OK' && predictions) {
          const streetAddressPredictions = predictions.filter((prediction: any) => prediction.types.includes('geocode'));
          if (cb) {
            cb(streetAddressPredictions);
          } else {
            this.handleAddressPredictions(streetAddressPredictions);
          }
        } else {
          this.options = [];
        }
      }
    );
  }

  private handleAutoSearch(predictions: any[]) {
    if (predictions && predictions.length > 0) {
      const prediction = predictions[0];
      const placeService = new google.maps.places.PlacesService(document.createElement('div'));
      placeService.getDetails({ placeId: prediction.place_id }, (placeResult, status) => {
        if (status === 'OK' && placeResult) {
          const addressComponents = placeResult.address_components;

          const zipCodeComponent = addressComponents?.find((component) => component.types.includes('postal_code'));
          const zipcode = zipCodeComponent ? zipCodeComponent.short_name : '';
          this.parent.get(this.street)?.setValue(addressComponents?.find((component) => component.types.includes('street_number'))?.long_name);
          this.parent.get(this.city)?.setValue(addressComponents?.find((component) => component.types.includes('locality'))?.long_name);
          this.parent
            .get(this.state)
            ?.setValue(addressComponents?.find((component) => component.types.includes('administrative_area_level_1'))?.short_name.toLowerCase());
          this.parent.get(this.zipcode)?.setValue(zipcode);
        }
      });
    }
  }

  private handleAddressPredictions(predictions: any[]) {
    const addressesWithZipCodes = predictions.map((prediction) => {
      const placeService = new google.maps.places.PlacesService(document.createElement('div'));
      return new Promise((resolve, reject) => {
        placeService.getDetails({ placeId: prediction.place_id }, (placeResult, status) => {
          if (status === 'OK' && placeResult) {
            const addressComponents = placeResult.address_components;
            const street = addressComponents?.find((component) => component.types.includes('street_number'))?.long_name;
            const streetName = addressComponents?.find((component) => component.types.includes('route'))?.long_name;
            const zipCodeComponent = addressComponents?.find((component) => component.types.includes('postal_code'));
            const zipcode = zipCodeComponent ? zipCodeComponent.short_name : '';
            const city = addressComponents?.find((component) => component.types.includes('locality'))?.long_name;
            const state = addressComponents?.find((component) => component.types.includes('administrative_area_level_1'))?.short_name.toLowerCase();
            resolve({ ...prediction, zipcode, city, state, street, streetName });
          } else {
            reject(status);
          }
        });
      });
    });

    // Once all promises are resolved, you can update your UI with the ZIP codes
    Promise.all(addressesWithZipCodes)
      .then((predictionsWithZipCodes) => {
        // Handle the predictionsWithZipCodes array, which includes ZIP codes
        this.options = predictionsWithZipCodes;
      })
      .catch((error) => {
        // Handle any errors here
        console.error('Error fetching ZIP codes:', error);
      });
  }
}
